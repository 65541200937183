// const apiUrl = "http://localhost:5207";
// const apiUrl="https://api.nords.ai";

// const apiUrlTracking = apiUrl + "/tracking";
// const apiUrlInfluencers = apiUrl + "/influencers";
// const apiUrlCampaigns = apiUrl + "/campaigns";
// const apiUrlUsers= apiUrl + "/users";
 
// const apiUrlTracking = "http://localhost:5208/api";
// const apiUrlInfluencers ="http://localhost:5135/api";
// const apiUrlCampaigns = "http://localhost:5227/api";
// const apiUrlUsers="http://localhost:5231/api";

const apiUrl=process.env.NODE_ENV=='development'?"http://localhost:5207":"https://api.nords.ai";

const apiUrlTracking = process.env.NODE_ENV=='development'?"http://localhost:5208/api":apiUrl + "/tracking";
const apiUrlInfluencers = process.env.NODE_ENV=='development'?"http://localhost:5135/api":apiUrl + "/influencers";
const apiUrlCampaigns = process.env.NODE_ENV=='development'?"http://localhost:5227/api":apiUrl + "/campaigns";
const apiUrlUsers= process.env.NODE_ENV=='development'?"http://localhost:5231/api":apiUrl + "/users";

const indicators = [
    { value: 1, label: "App" },
    { value: 2, label: "Campaign" },
    { value: 3, label: "Platform" },
    { value: 4, label: "Influencer" },
    { value: 5, label: "Country" }
];

const metrics = [
    { value: 1, label: "View" },
    { value: 2, label: "Click" },
    { value: 3, label: "Install" },
    { value: 4, label: "Conversation" },
    { value: 5, label: "CPI" }
];

const dateRanges = [
    { value: "Today", label: "TODAY" },
    { value: "ThisWeek", label: "THIS WEEK" },
    { value: "LastWeek", label: "LAST WEEK" },
    { value: "ThisMonth", label: "THIS MONTH" },
    { value: "LastMonth", label: "LAST MONTH" },
    { value: "Custom", label: "CUSTOM" }
];

// const apps = [
//     { value: "2bafa58c-81c1-46a7-aaf0-c8f5f9706de5", label: "Arrow Chain 3D" },
//     { value: "2bafa58c-81c1-46a7-aaf0-c8f5f9706de2", label: "Drop The Blocks 3D" }];

// const campaigns = [
//     { value: "8956dded-238e-4fec-b270-6cdda3169573", label: "Campaign 1" },
//     { value: "8956dded-238e-4fec-b270-6cdda3169574", label: "Campaign 2" }];
const platforms = [
    { value: 1, label: "Facebook", key: "Facebook", logo:"https://img.icons8.com/color/64/facebook-new.png" },
    { value: 2, label: "Instagram", key: "Instagram" , logo:"https://img.icons8.com/color/64/instagram-new--v1.png"},
    { value: 3, label: "Youtube", key: "Youtube" , logo:"https://img.icons8.com/color/64/youtube-play.png"},
    { value: 4, label: "TikTok", key: "Tiktok" , logo:"https://img.icons8.com/color/64/tiktok--v1.png"},
    { value: 5, label: "Twitter", key: "Twitter" , logo:"https://img.icons8.com/ios-filled/64/twitterx--v1.png"},
    { value: 6, label: "Other Platforms", key: "OtherPlatforms" , logo:"https://img.icons8.com/material-outlined/64/share.png"}];

const genders = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" }];

const locations = [
    { value: 1, label: "Türkiye" },
    { value: 2, label: "Germany" },
    { value: 3, label: "Netherlands" },
    { value: 4, label: "France" }];
const campaignTargets = [
    { value: 1, label: "App Install", key: "appinstall" },
    { value: 2, label: "Website Visit", key: "websitevisit" }];
const budgetTypes = [
    { value: 1, label: "Fixed Cost Values", key: "fixedcost" },
    { value: 2, label: "Total Budget", key: "total" }];
const interests = [
    { value: 1, label: "Activewear" },
    { value: 2, label: "Art & Design" },
    { value: 3, label: "Beauty & Cosmetics" },
    { value: 4, label: "Beer, Wine & Spirits" },
    { value: 5, label: "Business & Careers" },
    { value: 6, label: "Camera & Photograpgy" },
    { value: 7, label: "Cars & Motorbikes" },
    { value: 8, label: "Clothes, Shoes, Handbags & Accessories" },
    { value: 9, label: "Coffee, Tea & Beverages" },
    { value: 10, label: "Electronics & Computers" },
    { value: 11, label: "Fitness & Yoga" },
    { value: 12, label: "Friends, Family & Relationships" },
    { value: 13, label: "Gaming" },
    { value: 14, label: "Healthcare & Medicine" },
    { value: 15, label: "Healty Lifestyle" },
    { value: 16, label: "Home Decor, Furniture & Garden" },
    { value: 17, label: "Jewellery & Watches" },
    { value: 18, label: "Luxury Goods" },
    { value: 19, label: "Music" },
    { value: 20, label: "Pets" },
    { value: 21, label: "Restaturants, Food & Grocery" },
    { value: 22, label: "Shopping & Retail" },
    { value: 23, label: "Sports" },
    { value: 24, label: "Television & Film" },
    { value: 25, label: "Tobacco & Smoking" },
    { value: 26, label: "Toys, Children & Baby" },
    { value: 27, label: "Travel, Tourism & Aviation" },
    { value: 28, label: "Wedding" }];


export {
    indicators, metrics, dateRanges,
    //  apps,
    //   campaigns,
       platforms, genders,
    locations, interests, apiUrlTracking, apiUrlInfluencers, apiUrl, campaignTargets, budgetTypes, apiUrlCampaigns
};